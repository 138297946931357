<template>
  <tr>
    <wi-list-data-table-actions :config="config" :props="props"></wi-list-data-table-actions>
    <td class="justify-center">
      {{props.item.item_id}}
    </td>
    <td class="justify-center">
      <strong>{{props.item.item.name}}</strong>
    </td>
    <td class="justify-center">
      <strong style="font-size: 20px;" :style="{color: getItemColor(props.item)}">{{props.item.quantity}}</strong>
      <strong>{{props.item.item.unit.code}}</strong>
      <v-progress-linear :size="5" :width="1" :color="getItemColor(props.item)" :value="calculateQuantityMedia(props.item)" style="margin: 0px !important; max-width: 100px;"></v-progress-linear>
    </td> 
    <td class="justify-center">
      {{props.item.stock.name}}
    </td>
    <td class="justify-center">
      {{props.item.amount_cost | money}}
    </td>
    <td class="justify-center">
      {{props.item.amount_sale | money}}
    </td>
    <td class="justify-center">
      <v-menu bottom left>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" >
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-tile @click="$router.push({name: 'stock-list', params: {page: 'stock-movement'}, query: {'where': 'item_id,' + props.item.item_id}})">
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon>swap_horiz</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Movimentações deste Produto</v-list-tile-title>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile @click="$router.push({name: 'stock-list', params: {page: 'stock-transfer'}, query: {'where': 'item_id,' + props.item.item_id}})">
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon>import_export</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Transferências deste produto</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
    </td>
  </tr>
</template>
<script>
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'StockItems',
    methods: {
      calculateQuantityMedia: function (item) {
        if (item.min != null && item.max != null) {
          var quantity = item.quantity - item.min
          return (quantity * 100) / (item.max - item.min)
        } else {
          return 5
        }
      },
      getItemColor: function (item) {
        if (item.min != null && item.max != null) {
          if (item.quantity <= item.min) {
            if (item.quantity < 1) {
              return '#000'
            } else {
              return '#b71c1c'
            }
          }
          if (item.quantity >= item.max) {
            return '#6a1b9a'
          } else {
            return '#00c853'
          }
        } else if (item.quantity < 1) {
          return '#000'
        } else {
          return '#00c853'
        }
      }
    },
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions
    }
  }
</script>
<style>
td {
  padding: 0 5px !important;
}
th {
  padding: 0 5px !important;
}
</style>